import { easepick } from '@easepick/bundle'
import { RangePlugin } from '@easepick/range-plugin'
import { LockPlugin } from '@easepick/lock-plugin'

document.addEventListener('turbolinks:load', () => {
  const elem = document.querySelector('.js-rangepicker')
  if (!elem) return

  const picker = new easepick.create({
    element: elem,
    css: [
      'https://cdn.jsdelivr.net/npm/@easepick/bundle@1.2.0/dist/index.css',
    ],
    zIndex: 9999,
    plugins: [RangePlugin, LockPlugin],
    LockPlugin: {
      minDate: new Date(),
      minDays: 4,
      maxDays: 4,
    },
    format: 'D MMM YYYY',
    setup(picker) {
      picker.on('select', (e) => {
        const { view, date, target } = e.detail
        const rangesField = document.body.querySelector('.js-ranges')
        rangesField.value = rangesField.value + `${rangesField.value !== '' ? ', ' : ''}${elem.value}`
        document.querySelector('.js-display-ranges').innerText = rangesField.value
      })
    },
  })
})
