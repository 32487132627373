document.addEventListener('turbolinks:load', () => {

  const searchBtn = document.querySelector('.js-search-btn')
  if (searchBtn) {
    const searchField = document.querySelector('.js-search-field')
    searchBtn.addEventListener('click', () => {
      loadResults(searchField.value)
    })
    searchField.addEventListener('keyup', (evt) => {
      if (evt.key === 'Enter') {
        loadResults(searchField.value)
      }
    })
  }
})

function loadResults(value) {
  if (value !== '') {
    const url = `${window.location.pathname}?q=${value}`
    Turbolinks.clearCache()
    Turbolinks.visit(url)
  }
}
