import Rails from '@rails/ujs'

document.addEventListener('turbolinks:load', () => {
  const basketForm = document.body.querySelector('.js-basket-form')
  if (basketForm) {
    document.body.querySelectorAll('.js-basket-amount').forEach(el => {
      el.addEventListener('change', () => {
        submit(basketForm)
      })
    })
    document.body.querySelectorAll('.js-basket-remove').forEach(el => {
      el.addEventListener('click', () => {
        el.nextElementSibling.value = 'true'
        submit(basketForm)
      })
    })

    // order bump
    const bumps = document.body.querySelectorAll('.js-bump-btn')
    if (bumps.length > 0) {
      bumps.forEach(el => {
        el.addEventListener('click', () => {
          el.nextElementSibling.value = false
          const ds = el.dataset
          dataLayer.push({
            event: "add_to_cart",
            ecommerce: {
              items: [
                {
                  item_id: ds.itemId,
                  item_name: ds.itemName,
                  currency: "GBP",
                  item_category: "Ebook",
                  price: ds.price,
                  quantity: 1
                }
              ]
            }
          })
          submit(basketForm)
        })
      })
    }

    const subField = document.body.querySelector('.js-basket-sub-field')
    const basketSubBtn = document.body.querySelector('.js-basket-sub-btn')
    if (basketSubBtn) {
      basketSubBtn.addEventListener('click', () => {
        subField.value = 'true'
        submit(basketForm)
      })
    }
    const basketUsualBtn = document.body.querySelector('.js-basket-usual-btn')
    if (basketUsualBtn) {
      basketUsualBtn.addEventListener('click', () => {
        subField.value = 'false'
        submit(basketForm)
      })
    }

  }
})

const submit = (form) => {
  Rails.fire(form, 'submit')
}
