document.addEventListener('turbolinks:load', () => {
  const courses = document.body.querySelector('.js-courses-select')
  const ages = document.body.querySelector('.js-ages-select')

  const updateUrl = () => {
    let url = window.location.pathname + '?'
    if (courses && courses.value) {
      url += `course_id=${courses.value}&`
    }
    if (ages && ages.value) {
      url += `age_id=${ages.value}`
    }
    Turbolinks.visit(url)
  }

  if (courses) {
    courses.addEventListener('change', updateUrl)
  }

  if (ages) {
    ages.addEventListener('change', updateUrl)
  }
})
