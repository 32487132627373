// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import "bootstrap";
import "youtube";
require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");
require("packs/root");
window.$ = window.jQuery = require('jquery')
require('jquery-ui-bundle');
require('jquery-datetimepicker');

const requireJs = require.context('../src/js', false, /\.(js)$/)
requireJs.keys().forEach(requireJs)

import Cookies from 'js-cookie'
// all styles in folder
const requireApplication = require.context('src/css/autoload', false, /\.(css|scss|sass)$/)
requireApplication.keys().forEach(requireApplication)

require("trix")
require("@rails/actiontext")

window.Cookies = Cookies
