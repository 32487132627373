import Splide from '@splidejs/splide'
import '../css/modules/splide.scss'

document.addEventListener('turbolinks:load', () => {
  if (document.querySelector('.splide-revs')) {
    initRevsSplide()
  }
  if (document.querySelector('.splide-banners')) {
    initBannersSplide()
  }
  if (document.querySelector('.splide-mentors')) {
    initMentorsSplide()
  }
  if (document.querySelector('.splide-testimonials')) {
    initTestimonialsSplide()
  }
  if (document.querySelector('.js-splide-outcomes')) {
    initSplideOutcomes()
  }
})

const initRevsSplide = () => {
  window.splideRevs = new Splide('.splide-revs', {
    autoplay: true,
    type: 'loop',
    gap: '16px',
    fixedWidth: '296px',
    perMove: 1,
    pagination: false,
    focus: 'center',
    arrows: false,
    mediaQuery: 'min',
    breakpoints: {
      1024: {
        gap: '28px',
        arrows: true,
        padding: { left: 80, right: 0 },
        focus: null
      },
    }
  }).mount()
}

const initBannersSplide = () => {
  window.splideBanners = new Splide('.splide-banners', {
    autoplay: true,
    type: 'loop',
    perPage: 1,
    perMove: 1,
    arrows: false,
    mediaQuery: 'min',
    breakpoints: {
      820: {
        arrows: true
      },
    }
  }).mount()
}

const initMentorsSplide = () => {
  window.splideMentors = new Splide('.splide-mentors', {
    autoplay: true,
    type: 'loop',
    gap: '16px',
    perMove: 1,
    fixedWidth: '296px',
    fixedHeight: '400px',
    focus: 'center',
    pagination: false,
    arrows: false,
    mediaQuery: 'min',
    breakpoints: {
      1024: {
        destroy: true
      },
    },
  }).mount()
}

const initTestimonialsSplide = () => {
  window.splideTesti = new Splide('.splide-testimonials', {
    autoplay: true,
    type: 'loop',
    gap: '5px',
    perMove: 1,
    perPage: 3,
    breakpoints: {
      1200: {
        perPage: 2
      },
      810: {
        perPage: 1,
        arrows: false, // reload to see arrows
      },
    },
    classes: {
      prev  : 'splide__arrow--prev splide__arrow--prev_testi',
      next  : 'splide__arrow--next splide__arrow--next_testi',
    },
  }).mount()
}

const initSplideOutcomes = () => {
  window.splideOutcomes = new Splide('.js-splide-outcomes', {
    // autoplay: true,
    gap: '16px',
    fixedWidth: '216px',
    perMove: 1,
    pagination: false,
    arrows: false,
    mediaQuery: 'min',
    padding: { left: 15, right: 0 },
    breakpoints: {
      980: {
        fixedWidth: '278px',
        gap: '28px',
        arrows: true,
        focus: null
      },
      1268: {
        padding: { left: 'calc((100% - 1230px) / 2)', right: 0 },
      },
    }
  }).mount()
}

document.addEventListener('turbolinks:before-cache', () => {
  if (window.splideRevs) {
    window.splideRevs.destroy()
  }
  if (window.splideBanners) {
    window.splideBanners.destroy()
  }
  if (window.splideMentors) {
    window.splideMentors.destroy()
  }
  if (window.splideTesti) {
    window.splideTesti.destroy()
  }
  if (window.splideOutcomes) {
    window.splideOutcomes.destroy()
  }
})
