document.addEventListener('turbolinks:load', () => {
  const menuBtn = document.querySelector('.js-menu__btn')
  const menu = document.querySelector('.js-menu__canhide')
  const menuClose = document.querySelector('.js-menu__close-btn')
  const mainWrapper = document.querySelector('.js-main-wrapper')
  const menuDash = document.querySelector('.js-collapse')

  const body = document.body
  const basketHideable = document.querySelector('.js-basket-hideable')
  const basketBtn = document.querySelector('.js-basket-btn')
  const basketClose = document.querySelector('.js-basket-close')
  const overlay = document.querySelector('.js-overlay')
  const btnView = document.body.querySelector('.js-view-basket')
  const btnEnrol = document.body.querySelector('.js-enrol')
  const btnEarlybird = document.body.querySelector('.js-btn-earlybird')

  function viewBasket() {
    if (basketHideable.classList.contains('bash__hideable_open')) {
      basketHideable.classList.remove('bash__hideable_open')
      body.classList.remove('for-sidebar')
      overlay.classList.remove('bash__overlay_open')
    } else {
      basketHideable.classList.add('bash__hideable_open')
      body.classList.add('for-sidebar')
      overlay.classList.add('bash__overlay_open')
    }
  }

  function closeBasket() {
    basketHideable.classList.remove('bash__hideable_open')
    body.classList.remove('for-sidebar')
    overlay.classList.remove('bash__overlay_open')
  }

  if (btnView) {
    btnView.addEventListener('click', () => {
      viewBasket()
    })
  }

  if (btnEnrol) {
    btnEnrol.addEventListener('click', () => {
      viewBasket()
    })
  }

  if (btnEarlybird) {
    btnEarlybird.addEventListener('click', () => {
      viewBasket()
    })
  }

  if (basketHideable && basketBtn && basketClose) {
    document.body.addEventListener('click', el => {
      if (basketHideable.classList.contains('bash__hideable_open')
        && el.target !== menu
        && !el.target.closest('.js-basket-btn')
        && !el.target.closest('.btn-earlybird')
        && el.target !== btnView
        && el.target !== btnEnrol
        && !el.target.closest('.js-btn-earlybird')
        && (!basketHideable.contains(el.target)
          || el.target === basketClose
          || el.target.closest('.js-basket-close'))) {
        closeBasket()
      }
    })

    basketBtn.addEventListener('click', () => {
      viewBasket()
    })

    // window.addEventListener('resize', () => {
    //   closeBasket()
    // })
  }

  if ( (menu && mainWrapper) || menuDash) {
    menuBtn.addEventListener('click', () => {
      if (menu && mainWrapper) {
        menu.classList.toggle('menu__canhide_open')
        mainWrapper.classList.toggle('main-wrapper_overlay')
      } else if (menuDash) {
        menuDash.classList.toggle('dashboard-nav_open')
      }
    })
  }

  if (menu && menuClose) {
    menuClose.addEventListener('click', () => {
      menu.classList.remove('menu__canhide_open')
      mainWrapper.classList.remove('main-wrapper_overlay')
    })
  }

  if (menuDash && menuClose) {
    menuClose.addEventListener('click', () => {
      menuDash.classList.remove('dashboard-nav_open')
    })
    window.addEventListener('resize', () => {
      menuDash.classList.remove('dashboard-nav_open')
    })
  }

  if (menu && mainWrapper) {
    window.addEventListener('resize', () => {
      menu.classList.remove('menu__canhide_open')
      mainWrapper.classList.remove('main-wrapper_overlay')
    })
  }

})
