document.addEventListener('turbolinks:load', () => {
  const cartTrack = document.body.querySelector('.js-cart-track')
  if (cartTrack) {
    const handler = evt => {
      const ds = cartTrack.dataset
      dataLayer.push({
        event: "add_to_cart",
        added_by_btn: evt.currentTarget.activeElement.dataset.kind,
        ecommerce: {
          items: [
            {
              item_id: ds.itemId,
              item_name: ds.itemName,
              currency: "GBP",
              item_category: ds.itemCategory,
              price: ds.price,
            }
          ]
        }
      })
    }

    document.addEventListener('ajax:before', handler)

    // prevent an event from firing multiple times
    document.addEventListener('turbolinks:before-cache', () => {
      document.removeEventListener('ajax:before', handler)
    })
  }
})

