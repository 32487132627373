document.addEventListener('turbolinks:load', () => {
  const box = document.body.querySelector('.js-checkout-box')
  const btn = document.body.querySelector('.js-checkout-btn')
  if (btn) {
    if (box) {
      box.addEventListener('click', () => {
        btn.disabled = !box.checked
      })
    }

    // Create an instance of the Stripe object with your publishable API key
    const stripe = Stripe(btn.dataset.skey)

    btn.addEventListener('click', () => {
      btn.disabled = true

      const items = $(btn).data('gtmitems').map(x => JSON.parse(x))

      dataLayer.push({
        event: "begin_checkout",
        transaction_id: btn.dataset.gtmid,
        value: btn.dataset.gtmvalue,
        currency: "GBP",
        ecommerce: {
          items: items
        }
      })

      fetch('/purchases', {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'X-CSRF-Token': document.head.querySelector("meta[name='csrf-token']").content
        }
      })
        .then(function (response) {
          return response.json()
        })
        .then(function(session) {
          return stripe.redirectToCheckout({ sessionId: session.id })
        })
        .then(function(result) {
          if (result.error) {
            alert(result.error.message)
          }
        })
        .catch(function (error) {
          console.error("Error:", error)
        })
    })
  }
})
