document.addEventListener('turbolinks:load', () => {
  const radios = document.body.querySelectorAll('.js-timezone-radio')
  if (radios) {
    radios.forEach(el => {
      el.addEventListener('change', () => {
        el.closest('form').submit()
      })
    })
  }
})
