document.addEventListener('turbolinks:load', () => {
  const campCheck = document.body.querySelector('.js-camp-check')
  const campElems = document.body.querySelectorAll('.js-camp-tab')
  const courseElems = document.body.querySelectorAll('.js-course-elem')
  if (campCheck && campElems && courseElems) {
    campCheck.addEventListener('change', () => {
      campElems.forEach(el => {
        if (campCheck.checked) {
          el.classList.remove('d-none')
        } else {
          el.classList.add('d-none')
        }
      })
      courseElems.forEach(el => {
        if (campCheck.checked) {
          el.classList.add('d-none')
        } else {
          el.classList.remove('d-none')
        }
      })
    })
  }
})
