import scrollSpy from 'simple-scrollspy'
import jumpTo from 'jump.js'

document.addEventListener('turbolinks:load', () => {
  const scrollMenu = document.body.querySelector('.js-scrollspy-menu')
  const horizontalContainer = document.body.querySelector('.js-prn-nav')
  if (!scrollMenu || !horizontalContainer) return

  const options = {
    sectionClass: '.js-scrollspy-section',
    menuActiveTarget: '.js-scrollspy-menu-item',
    smoothScroll: true,
    smoothScrollBehavior: function(element) {
      jumpTo(element, {
        duration: 180,
      })
    },
    onActive: el => {
      horizontalContainer.scrollLeft = el.offsetLeft
    }
  }
  scrollSpy(scrollMenu, options)
})
