import "jquery-ui-bundle";

document.addEventListener("turbolinks:load", function() {
  $(".notice, .alert, .alert-success, .alert-danger").fadeOut(13000);
  new_password();
});

function new_password() {
  var new_pwd = document.getElementsByClassName("new-password-eye");
  if (new_pwd && new_pwd.length !== 0) {
    $(new_pwd).on("click", function() {
      var x = $(this).closest(".password-container").find(".new-password");
      if (x[0].type === "password") {
        x.prop('type', 'text');
      } else {
        x.prop('type', 'password');
      }
    });
  }
}
