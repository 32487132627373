document.addEventListener('turbolinks:load', () => {
  const dataHolder = document.body.querySelector('.js-data-holder')
  if (!dataHolder) return

  const items = $(dataHolder).data('gtmitems').map(x => JSON.parse(x))
  const ds = dataHolder.dataset
  dataLayer.push({
    event: "purchase",
    transaction_id: ds.gtmid,
    value: ds.gtmvalue,
    currency: "GBP",
    returning: ds.returning === "true",
    ecommerce: {
      items: items
    }
  })
})
